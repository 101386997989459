<template>
  <form action class="helloForm" @submit.prevent="submit">
    <div
      class="field-group"
      v-for="({ name, type, label, required }, i) in $t('helloForm')"
      :key="i"
    >
      <label :for="name">{{ label }}</label>
      <input v-if="name !== 'hiMessage'" :name="name" :type="type" :required="required" />
      <textarea v-if="name === 'hiMessage'" :name="name" id cols="30" rows="10"></textarea>
    </div>

    <div class="text-right submit-wrapper pt-2 pb-2">
      <Loader v-if="isSubmitting" style="margin-bottom: -6.5px;" />
      <button v-else type="submit" style="height:40px">{{ $t("global.send") }}</button>
    </div>

    <ThankYouModal />
  </form>
</template>

<script>
import axios from 'axios'
export default {
  data () {
    return {
      formField: this.$t('helloForm'),
      isSubmitting: false
    }
  },
  methods: {
    submit (ev) {
      this.isSubmitting = true

      const formData = new FormData(ev.target)

      const url =
        'https://api.irix-design.com/api/sayhello/v1'

      try {
        axios.post(url, formData).then(res => {
          this.$root.$emit('bv::show::modal', 'service-ty-modal')
          this.isSubmitting = false
        })
      } catch (error) {
        this.isSubmitting = false
        this.errors.push(error)
      }
    },
    toCamal (str) {
      return str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
          return index === 0 ? word.toLowerCase() : word.toUpperCase()
        })
        .replace(/\s+/g, '')
        .replace('/', '')
    }
  },
  components: {
    ThankYouModal: () => import('./ThankYouModal'),
    Loader: () => import('./Loader.vue')
  }

}
</script>

<style lang="scss">
.helloForm {
  .field-group {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    input {
      background: transparent;
      border: none;
      border-bottom: 1px solid #fff;
      border-radius: none;
      color: #fff;
      &:focus-visible {
        outline: none;
      }
    }
    textarea {
      background: transparent;
      color: #fff;
      border: 0;
      border-bottom: 1px solid #fff;
    }
  }
  .submit-wrapper {
    button {
      // margin-bottom: 1rem;
      background: none;
      color: #fff;
      border: 0;
    }
    border-bottom: 1px solid #fff;
  }
}
</style>
