<template>
  <div class="page-sayHello">
    <section>
      <div class="content">
        <p style="max-width: 350px;">{{ $t("sayHello.title") }}</p>
      </div>
    </section>

    <div class="line-left"></div>

    <section>
      <div class="content">
        <b-row>
          <b-col cols="12" md="6">
            <p>{{ $t("sayHello.contactTitle1") }}</p>
            <p>
              <span>{{ $t("company.name") }}</span>
              <br />
              <a
                href="https://www.google.ca/maps/place/Irix+Design+Group+Inc/@49.2634885,-123.1237223,17z/data=!3m1!4b1!4m2!3m1!1s0x548673c325dd02c1:0xd0860ccbf8134b47">
                <span class="m-0 mr-3" v-html="$t('company.address')"></span>
              </a>
              <br />
              <!-- <span>{{ $t("company.postal") }}</span> -->
            </p>
            <div class="contact-wrapper mb-5">
              <div v-for="(items, i) in $t('company.contact')" :key="i">
                <div v-if="i !== 'email'">
                  <span class="text-capitalize">{{ i }} : </span>
                  <a :href="`tel:${i}`">{{ items }}</a>
                </div>
                <div v-if="i === 'email'">
                  <span class="text-capitalize">{{ i }} : </span>
                  <a :href="`mailto:${items}`">{{ items }}</a>
                </div>
              </div>
            </div>
            <!-- <p class="mb-5">OUR GLOBAL NETWORK OF COMPANIES</p> -->
            <p>{{ $t("sayHello.contactTitle") }}</p>

            <div class="mb-5">
              <h5>IRIX Design Schweiz</h5>
              <span>{{ $t("sayHello.chdes") }}</span>
              <p>{{ $t("global.email") }}: <a href="mailto:hello@irixdesign.ch">hello@irixdesign.ch</a></p>
              <p>{{ $t("sayHello.switzerland") }}</p>
            </div>

            <div class>
              <h5>Atelier H</h5>
              <span>{{ $t("sayHello.hkDes") }}</span>
            </div>

            <p>{{ $t("global.email") }}: <a href="mailto:renne@atelierh.com.hk">renne@atelierh.com.hk</a></p>
            <p>{{ $t("sayHello.hk") }}</p>
          </b-col>

          <b-col cols="12" md="6">
            <HelloForm></HelloForm>
          </b-col>
        </b-row>
      </div>
    </section>
  </div>
</template>

<script>
import HelloForm from '../components/HelloForm'

export default {
  components: {
    HelloForm
  }
}
</script>

<style lang="scss">
.page-sayHello {
  margin-top: 150px;

  .content {
    width: 800px;
    max-width: 100%;

    .col-12 {
      margin-bottom: 2rem;
    }

    .col-12:last-child {
      margin-bottom: 0;
    }
  }

  .line-left {
    height: 2px;
    background: #fff;
    margin-bottom: 100px;
    width: 100%;
    transform: inherit;
  }

  @media (min-width: 768px) {
    .line-left {
      transform: translateX(calc((100% - 800px) / -2));
    }
  }
}
</style>
